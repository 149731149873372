import React from "react";
import { connect } from "react-redux";
import { settings } from "../settings";

import { getAuctionCarData, clearAuctionData } from "../actions/auction_car_actions";
import { setTranslation } from "../actions/auction_car_actions";

import getSortedCarImages from "../widgets/getSortedCarImages";
import LoadingSpinner from "../widgets/LoadingSpinner";
import LoadingError from "../widgets/LoadingError";

import AuctionCarDetails from "./AuctionCarDetails";

import "./AuctionCarPrintable.css";

// Max number of images that can be displayed below the auction sheet. This
// limit should ensure any additional images fit on the first page. Auction
// sheets can have different dimensions, but I found that for taller sheets,
// 3 rows of 3 images each will fit on the first page.
const MAX_SMALL_IMAGES = 9;

// Since the component from ./auction_car_views/Tranlsation returns a Table
// within a Card with a lot of formatting, this component is used to display
// the translation data instead
function TranslationTable({translationData})
{
    // no data? don't render anything
    if (translationData?.found !== true)
        return null;

    // Add table rows for all fields in `main`
    const rows = [];
    try
    {
        const main = JSON.parse(translationData.main);
        for (const k in main)
        {
            // This one is a list of items with no labels, most of which are
            // also shown in AuctionCarData in a nicer format
            if (k === "Options")
                continue;

            rows.push(<tr key={k}>
                <td><b>{k}</b></td><td>{main[k]}</td>
            </tr>);
        }
    }
    catch (e)
    {
        console.log("Failed to parse translation.main: " + e);
    }

    // Add table rows for the other translation fields
    const EXPECTED_KEYS = [
        "notes", "sales_report", "report", "diagram_notes", "exporter_notes"
    ];
    for (const k of EXPECTED_KEYS)
    {
        const displayName = k[0].toUpperCase() + k.slice(1).replaceAll("_", " ");
        if (translationData[k])
        {
            rows.push(<tr key={k}>
                <td><b>{displayName}</b></td><td>{translationData[k]}</td>
            </tr>);
        }
    }

    return <div>
        <div style={{textAlign: "center"}}>
            <b>Auction Sheet Translation</b>
        </div>
        <table className="translation-table">
            <tbody>
                {rows}
            </tbody>
        </table>
    </div>;
}

/**
 * Return a full page in a printer-friendly format with all of the information
 * about an auction car (car details, and translation data if available). The
 * auction sheet is very large while the other pictures are much smaller.
 * @param {any} props
 */
function AuctionCarPrintable(props)
{
    // Track status of loading the car, images and translation, so we know when
    // the page is ready to print
    const [carLoaded, setCarLoaded] = React.useState(false);
    const [carError, setCarError] = React.useState(false);
    const [images, setImages] = React.useState(null);
    const [translationLoaded, setTranslationLoaded] = React.useState(false);

    const auctionID = props.match.params["auction_id"];

    // We don't use <PCANav> on this page so we need to set the title ourselves.
    // Include the auction ID in the title because the page title is the default
    // file name when printing (make the default file name unique for each car).
    document.title = "Auction Information " + auctionID;

    // Fetch data on mount, clear auction car on unmount
    React.useEffect(() => {
        // Another effect will update carLoaded/Error after redux loads the car
        setCarLoaded(false);
        setCarError(false);
        getAuctionCarData(auctionID);

        // Get images, auction sheet will be first
        setImages(null);
        getSortedCarImages(auctionID).then(
            images => setImages(images),
            reason => {
                console.log("Error getting images for car: " + reason);
                setImages([]);
            }
        );

        // Get translation
        setTranslationLoaded(false);
        const translationURL = settings.api_server + "/translations/access/" + auctionID;
        fetch(translationURL, {"credentials": "include"})
            .then(resp => resp.ok?
                resp.json()
                :
                Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(
                data => {
                    props.setTranslation(
                        data.found, data, data.translation_completed
                    );
                },
                reason => {
                    console.log("Failed to get translation. Reason: " + reason);
                    props.setTranslation(false, undefined, false);
                }
            )
            .finally(() => setTranslationLoaded(true));

        // return function to run on unmount (clear auction data)
        return props.clearAuctionData;
    }, [auctionID]);

    // When redux loads the auction data, set our ready flag
    React.useEffect(() => {
        if (!props.loading)
        {
            if (props.data === null)
                setCarError(true);
            else if (props.data.vehicle_id === auctionID)
                setCarLoaded(true);
        }
    }, [props.loading]);

    // After each render, override some site-wide styles (such as making the
    // background white to save ink)
    React.useEffect(() => {
        // Problem: App.css sets the <body>'s background-color to light blue,
        // and if we import a style sheet which overrides it, it will override
        // it for the whole site.
        // Solution: set body's inline CSS on this page.
        const body = document.getElementsByTagName("body")[0];
        const oldBodyStyle = body.getAttribute("style");
        body.setAttribute("style", "background-color: white !important;");

        // Now the footer's copyright disclaimer is white-on-white, so modify
        // it as well.
        const copyrightDiv = document.getElementsByClassName("pcaFooter")[0];
        const copyrightText = copyrightDiv.getElementsByTagName("a")[0];
        const oldCopyrightStyle = copyrightText.getAttribute("style");
        copyrightText.setAttribute("style",
            "color: black;" +
            "text-shadow: none;" +
            "font-weight: normal;"
        );

        // Problem: if we navigate directly to this page then back to another
        // page on the site, our inline style makes the site look all wrong.
        // Solution: restore the old styles on dismount.
        return () => {
            body.setAttribute("style", oldBodyStyle);
            copyrightText.setAttribute("style", oldCopyrightStyle);
        };
    });

    // Once everything is loaded, wait for the images to render, then open a
    // print dialog
    const pageReady = carLoaded && !carError
        && images !== null && translationLoaded;
    React.useEffect(() => {
        if (!pageReady)
            return;

        const images = document.querySelectorAll("img");
        const pending = [];
        for (const img of images)
        {
            if (!img.complete)
            {
                pending.push(new Promise((resolve, reject) => {
                    img.addEventListener("load", resolve);
                    img.addEventListener("error", reject);
                }));
            }
        }
        Promise.all(pending).then(
            window.print,
            reason => console.log("Error loading images: " + reason)
        );
    }, [pageReady]);

    if (carError)
    {
        return <LoadingError message="Unable to load auction information"/>;
    }
    else if (!pageReady)
    {
        return <LoadingSpinner/>;
    }
    else
    {
        const auctionSheetURL = images[0];
        const otherImages = images.slice(1, MAX_SMALL_IMAGES + 1);
        const brandURL = settings.api_server + "/purchaseDetail/print/brand";
        // The style for the classes used here is defined in
        // AuctionCarPrintable.css
        return <div className="print-car-root">
            <div className="print-car-layout">
                {auctionSheetURL &&
                    <div className="images-column">
                        <img className="auction-sheet"
                            src={auctionSheetURL}
                            alt="Auction sheet"
                        />
                        <div className="other-images">
                            {otherImages.map(url => <div key={url}>
                                <img src={url} alt="Image of car"/>
                            </div>)}
                        </div>
                    </div>
                }
                <div>
                    <div className="print-car-header">
                        <img src={brandURL} alt="Branding header"/>
                    </div>
                    <TranslationTable
                        translationData={props.translation}
                    />
                    <div style={{textAlign: "center"}}>
                        <b>Car Details</b>
                    </div>
                    <AuctionCarDetails/>
                </div>
            </div>
        </div>;
    }
}

const mapStateToProps = state => {
    return {
        data: state.auction_car.data,
        loading: state.auction_car.loading,

        translation: state.auction_car.translation,
        translation_loaded: state.auction_car.translation_loaded,
        requested_translation: state.auction_car.requested_translation,
        translation_completed: state.auction_car.translation_completed,

        requestedImageList: state.image_request_list.current_image_list,
        numRequested: state.image_request_list.num_images,
    };
};

const mapDispatchToProps = dispatch => ({
    clearAuctionData: () => dispatch(clearAuctionData()),
    setTranslation: (requested, data, complete) => dispatch(
        setTranslation(requested, data, complete)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionCarPrintable);
