import React, { Component } from "react";

import { Link } from "react-router-dom";

import moment from "moment-timezone";

import {
    Image
} from "react-bootstrap";

import {
    allowEntities,
} from "../../functions";

import spaceNumbers from "../../widgets/spaceNumbers";

export default class ResultRow extends Component {
    constructor () {
        super();
        this.getImages = this.getImages.bind(this);
    }

    getImages (imageList) {
    // given a list of images. Ensure it is long enough
        const images = [];

        const style = { paddingRight: "5px" }; // space it out

        // remove duplicates
        const imageSplit = [...new Set(imageList)];
        if (imageSplit.length > 0) { images.push(<Image fluid style={style} key={imageSplit[0]} src={imageSplit[0]} />); }
        // moved to 1 image, too weak to pull this out.
        // if (imageSplit.length > 1)
        // bootstrap voodoo https://getbootstrap.com/docs/4.0/utilities/display/
        // hide second image on small displays
        //    images.push(<Image fluid className="d-none d-sm-none d-md-inline" style={style} key={imageSplit[1]} src={imageSplit[1]} />);
        return images;
    }

    render () {
        const result = this.props.car;

        const auction_time = moment.tz(result.auction_time, "Asia/Tokyo");
        const nowJST = moment.tz("Asia/Tokyo");
        // CSS class for Auction Time <td>
        let dateColor = "auctionOK";
        // the contents of the <td>
        let dateDisplay = null;
        // Special cases:
        // if it's over 7 days out, it may be a placeholder for an unset date
        if (auction_time.isAfter(moment().add(7, "days"))) {
            dateDisplay = "Date not set";
        }
        // if it's at midnight, then the auction date is set but the time isn't
        else if (auction_time.hour() === 0) {
            // we might use our own format for the relative time since Japan's
            // day of the month might not match the local day of the month,
            // making the relative date confusing
            let fromNow;
            // If it's before today, the auction has passed
            if (auction_time.isBefore(nowJST, "day")) {
                dateColor = "auctionPassed";
                fromNow = auction_time.fromNow();
            }
            // If the time is midnight today, the auction should happen at some
            // point today.
            else if (auction_time.isSame(nowJST, "day")) {
                dateColor = null; // use table row color
                fromNow = "Today, time not set";
            }
            else {
                // (keep auctionOK class)
                fromNow = "Time not set";
            }
            // Show date, not time. Include time zone so date isn't ambiguous.
            dateDisplay = <span>{auction_time.format("D MMM YYYY z")}<br/>{fromNow}</span>;
        }
        // regular auction date
        else {
            dateDisplay = <span>{auction_time.format("D MMM YYYY HH:mm z")}<br/>{auction_time.fromNow()}</span>;
            // auction passed
            if (auction_time.isBefore(nowJST)) {
                dateColor = "auctionPassed";
            }
            // auction near
            else if (auction_time.isBefore(nowJST.clone().add(1, "hours"))) {
                dateColor = "auctionNear";
            }
            // (if it's further out, keep auctionOK class)
        }

        // if we have something more to show, show it.
        let more = null;
        if ("children" in this.props) {
            more = <div>{this.props.children}</div>;
        }

        return <tr id={result.vehicle_id} key={result.vehicle_id} style={{cursor: "pointer"}}>
            {/* Auction Info */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {result.auction_house}<br/>{result.lot}
                </Link>
            </td>
            {/* Image */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {this.getImages(result.car_image_list)}
                </Link>
            </td>
            {/* Year */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {result.year}
                </Link>
            </td>
            {/* Chassis */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {allowEntities(result.chassis_code)}
                </Link>
            </td>
            {/* Make */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {result.make}
                </Link>
            </td>
            {/* Model */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {result.model}
                </Link>
            </td>
            {/* Submodel */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {result.submodel}
                </Link>
            </td>
            {/* Transmission */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {result.transmission}
                </Link>
            </td>
            {/* Engine */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {result.displacement}
                </Link>
            </td>
            {/* Grade */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {result.grade}
                </Link>
            </td>
            {/* Odometer */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {spaceNumbers(result.mileage * 1000) + " km"}
                </Link>
            </td>
            {/* Auction Time */}
            <td className={dateColor}>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {dateDisplay}
                </Link>
                {more}
            </td>
        </tr>;
    }
}
