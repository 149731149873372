import React from "react";
import { connect } from "react-redux";

import {
    ListGroup
} from "react-bootstrap";

import { settings } from "../settings";

import commaNumbers from "../widgets/commaNumbers";

import "./BidLimits.css";

/**
 * Component for displaying the user's current bid limit, a breakdown of all bid
 * limits, or both.
 * @param {Object} props
 * @param {boolean} [props.showUserLimit] show the user's limit, default true.
 * @param {boolean} [props.showLimitList] show all bid limits, default true.
 * @param {boolean} props.guest (redux) if true, the user is a guest
 * @param {number} props.deposit (redux) the user's account balance
 */
function BidLimits({
    showUserLimit = true,
    showLimitList = true,
    guest,
    deposit,
})
{
    /**
     * @typedef {Object} BidLimitObj
     * @prop {number} deposit_minimum
     * @prop {number} bid_maximum
     */
    /** @type {[BidLimitObj[], (list: BidLimitObj[]) => void]} */
    const [limits, setLimits] = React.useState([]);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        const url = settings.api_server + "/bids/limits";
        fetch(url, {credentials: "include"})
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(resp.status + " " + resp.statusText)
            )
            // the endpoint returns an empty array if something goes wrong, we
            // don't need to check for a "success" flag
            .then(
                limitList => setLimits(
                    limitList.sort((a, b) => a.bid_maximum - b.bid_maximum)
                ),
                setError
            );
    }, [guest, deposit]);

    if (error)
    {
        return <ListGroup className="bid-limit-list">
            <ListGroup.Item>
                Error fetching bid limits: {error}
            </ListGroup.Item>
        </ListGroup>;
    }
    else
    {
        const myLimit = Math.max(...limits
            .filter(l => deposit >= l.deposit_minimum)
            .map(l => l.bid_maximum)
        );
        return <ListGroup className="bid-limit-list">
            {showLimitList && limits.map(l => <ListGroup.Item
                key={l.bid_maximum}
            >
                To bid up to &yen;{commaNumbers(l.bid_maximum)} you require a
                deposit of &yen;{commaNumbers(l.deposit_minimum)} or more.
            </ListGroup.Item>)}
            {showUserLimit && !guest && myLimit >= 0 && <ListGroup.Item>
                Your bid maximum is &yen;{commaNumbers(myLimit)}.
            </ListGroup.Item>}
        </ListGroup>;
    }
}

const mapStateToProps = state => {
    return {
        guest: state.profile.guest,
        deposit: state.profile.balance
    };
};

/**
 * @param {Object} props
 * @param {boolean} [props.showUserLimit] show the user's limit, default true.
 * @param {boolean} [props.showLimitList] show all bid limits, default true.
 */
export default connect(mapStateToProps)(BidLimits);
