
import React, { Component } from "react";
import "./App.css";
import { Link } from "react-router-dom";

import PCANav from "./dashboard-components/navbar";

import { connect } from "react-redux";

import { settings } from "./settings";
import AdminOnly from "./dashboard-components/AdminOnly";
import { setAllPurchases, showAddPayment, hideAddPayment } from "./actions/allpurchases_actions";

import {
    Alert,
    Container, Row, Col,
    Table,
    ListGroup,
    Pagination,
    Button,
    Modal,
    Form
} from "react-bootstrap";

import * as Datetime from "react-datetime";

const invoices_per_page = 10;

// When we fetch the payment methods, if this one is present, use it as the
// default selection and autofill the Notes field with
// `Payment with ${DEFAULT_PAYTYPE}`
const DEFAULT_PAYTYPE = "Payline";

class PaymentModalComponent extends Component {
    constructor () {
        super();
        this.addInvoiceData = this.addInvoiceData.bind(this);
        this.changeAmount = this.changeAmount.bind(this);
        this.changeDetail = this.changeDetail.bind(this);
        this.getPayTypes = this.getPayTypes.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);

        this.state = {
            amount: "",
            message: null,
            detail: "",
            working: false,
            payType: "",
            when_processed: "",
            typeOptions: []
        };
        // will be used to restore defaults upon completing a payment
        this.defaultState = {...this.state};
    }

    handleRadioChange (e) {
        const val = e.target.id;
        this.setState({ ...this.state, payType: val });
    }

    addInvoiceData () {
        const url = settings.api_server + "/invoice/admin/purchase/" + this.props.addPaymentPurchaseID + "/" + this.props.addPaymentInvoiceID + "/payment";

        // Payments are negative numbers, so autocorrect positive amounts to
        // negative amounts. But check for empty amounts first, since
        // Math.abs("") returns 0.
        if (this.state.amount === "") {
            this.setState({...this.state, message: "Amount cannot be empty"});
            return;
        }
        const amount = Math.abs(this.state.amount) * -1;
        const postdata = {
            amount,
            detail: this.state.detail,
            payment_type: this.state.payType,
            when_processed: this.state.when_processed
        };

        if (this.state.invoiceOther) {
            postdata.item_type = this.item_type_other.value;
        }

        this.setState({ ...this.state, working: true });
        fetch(url, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(postdata),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(body => body.success ?
                Promise.resolve() // don't need anything else from the body
                : Promise.reject(body.message)
            )
            .then(
                // On success, restore default values, refresh the invoice data,
                // and hide the modal.
                () => {
                    // Note: in addition to restoring the default <input>
                    // values, this will also set .working to false and clear
                    // any error messages.
                    this.setState(this.defaultState);
                    this.props.refresh(); // prop from InvoiceComponent below
                    this.props.hideAddPayment(); // redux dispatch
                },
                // On failure, update our state with the error message. Don't
                // restore defaults so the user doesn't lose their work.
                reason => {
                    this.setState({...this.state,
                        working: false,
                        message: reason,
                    });
                }
            );
    }

    changeAmount (e) {
        this.setState({ ...this.state, amount: e.target.value });
    }

    changeDetail (e) {
        this.setState({ ...this.state, detail: e.target.value });
    }

    // Called once, when the modal is first mounted. Fetches the available
    // payment types, and sets default values for the form.
    getPayTypes () {
        const url = settings.api_server + "/config_items/payment_types";

        fetch(url, {
            credentials: "include",
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    console.log(response);
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                const options = data.config_value.split(",");
                if (options.includes(DEFAULT_PAYTYPE)) {
                    this.defaultState.payType = DEFAULT_PAYTYPE;
                    this.defaultState.detail = `Payment with ${DEFAULT_PAYTYPE}`;
                }
                else {
                    this.defaultState.payType = "";
                    this.defaultState.detail = "";
                }
                this.defaultState.typeOptions = options;
                // Set the defaults the first time we get the options; we'll set
                // them again each time a payment is completed
                this.setState({
                    ...this.state,
                    typeOptions: options,
                    payType: this.defaultState.payType,
                    detail: this.defaultState.detail,
                });
            }.bind(this));
    }

    componentDidMount () {
        this.getPayTypes();
    }

    render () {
        const paymentType = this.state.typeOptions.map(x => <Form.Check key={x} onChange={this.handleRadioChange} checked={x === this.state.payType} type='radio' label={x} name='payType' id={x} />);

        return <Modal onHide={this.props.hideAddPayment} show={this.props.showAddPayment} >
            <Modal.Header>
                <Modal.Title>Add a payment</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Group controlId="Amount">
                    <Form.Label>Amount</Form.Label>
                    <Form.Control onChange={this.changeAmount} value={this.state.amount} />
                </Form.Group>

                <Form.Group controlId="Detail">
                    <Form.Label>Date of payment</Form.Label>
                    <Datetime closeOnSelect value={this.state.when_processed} onChange={(e) => this.setState({ ...this.state, when_processed: e.format("YYYY-MM-DD") })} dateFormat="YYYY-MM-DD" timeFormat={false} />
                </Form.Group>
                <Form.Group controlId="Detail">
                    <Form.Label>Payment Type</Form.Label>
                    {paymentType}
                </Form.Group>
                <Form.Group controlId="Detail">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control onChange={this.changeDetail} value={this.state.detail} />
                </Form.Group>
                {this.state.message !== null &&
                    <Alert variant="warning">{this.state.message}</Alert>
                }
            </Modal.Body>

            <Modal.Footer>
                <Button disabled={this.state.working} variant="primary" onClick={this.addInvoiceData}>Save changes</Button>
                <Button disabled={this.state.working} variant="secondary" onClick={this.props.hideAddPayment}>Close without saving</Button>
            </Modal.Footer>
        </Modal>;
    }
}
const mapStateToPropsInvoiceModal = state => {
    return {
        showAddPayment: state.allPurchases.showAddPayment,
        addPaymentPurchaseID: state.allPurchases.addPaymentPurchaseID,
        addPaymentInvoiceID: state.allPurchases.addPaymentInvoiceID
    };
};

const mapDispatchToPropsInvoiceModal = dispatch => ({
    hideAddPayment: () => dispatch(hideAddPayment())
});

const PaymentModal = connect(mapStateToPropsInvoiceModal, mapDispatchToPropsInvoiceModal)(PaymentModalComponent);

class InvoiceComponent extends Component {
    constructor () {
        super();
        this.paymentClick = this.paymentClick.bind(this);
    }

    paymentClick () {
    // Open the modal
        this.props.showAddPayment(this.props.data.purchase_id, this.props.data.invoice_id);
    }

    render () {
    // highlight green if there is no amount outstanding
        let style = null;
        if (this.props.data.amount <= 0)
        // this is the green of bootstrap buttons
        { style = { backgroundColor: "#53a451" }; }
        return (
            <tr>
                <td>{this.props.data.invoice_id}</td>
                <td>{this.props.data.released_to_client ? this.props.data.due_on_date : "Not released"}</td>
                <td>{this.props.data.purchase_complete ? "Closed" : "Open"}</td>
                <td>&#165;{Math.abs(this.props.data.invoiced_amount)}</td>
                <td>&#165;{Math.abs(this.props.data.paid_amount)}</td>
                <td style={style}>&#165;{this.props.data.amount}</td>
                <td><Button variant="outline-primary" onClick={this.paymentClick}>Payment</Button></td>
            </tr>
        );
    }
}
const mapStateToPropsInvoice = state => {
    state;
    return {
    };
};

const mapDispatchToPropsInvoice = dispatch => ({
    showAddPayment: (purchaseID, invoiceID) => dispatch(showAddPayment(purchaseID, invoiceID))
});

const Invoice = connect(mapStateToPropsInvoice, mapDispatchToPropsInvoice)(InvoiceComponent);

class Purchase extends Component {
    render () {
    /*
        Show this purchase header, and a list of it's invoices
        */
        const invoices = this.props.data.invoices.map(data => <Invoice key={data.invoice_id} data={data} />);
        return (
            <ListGroup.Item>
                <Row>
                    <Col md="3">{this.props.data.user.firstname} {this.props.data.user.lastname}</Col>
                    <Col md="3">{this.props.data.make} {this.props.data.model}</Col>
                    <Col md="3">{this.props.data.date_of_purchase}</Col>
                    <Col md="3"><Link to={"/editPurchase/" + this.props.data.purchase_id}>Go to purchase</Link></Col>
                </Row>

                {invoices.length > 0 &&
                    <Row>
                        <Col md="12">
                            <Table bordered size="sm">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Due on Date</th>
                                        <th>Complete</th>
                                        <th>Invoiced amount</th>
                                        <th>Paid amount</th>
                                        <th>Amount outstanding</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoices}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                }
            </ListGroup.Item>
        );
    }
}

class PurchasePager extends Component {
    constructor () {
        super();
        this.state = { page: 0 };

        this.firstPage = this.firstPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
    }

    firstPage () {
        this.setState({ ...this.state, page: 0 });
    }

    nextPage () {
        this.setState({ ...this.state, page: this.state.page + 1 });
    }

    prevPage () {
        this.setState({ ...this.state, page: this.state.page - 1 });
    }

    lastPage () {
        this.setState({ ...this.state, page: Math.floor((this.props.purchases.length - 1) / invoices_per_page) });
    }

    render () {
        const data = this.props.purchases.map(data => <Purchase key={data.purchase_id} data={data} />);
        const show = data.slice(this.state.page * invoices_per_page, (this.state.page + 1) * invoices_per_page);

        return <div>
            <ListGroup>
                {show}
            </ListGroup><Row>
                <Pagination>
                    <Pagination.First onClick={this.firstPage} disabled={this.state.page === 0} />
                    <Pagination.Prev onClick={this.prevPage} disabled={this.state.page === 0} />
                    <Pagination.Next onClick={this.nextPage} disabled={this.state.page === Math.floor((this.props.purchases.length - 1) / invoices_per_page)} />
                    <Pagination.Last onClick={this.lastPage} disabled={this.state.page === Math.floor((this.props.purchases.length - 1) / invoices_per_page)} />
                </Pagination>
            </Row>
        </div>;
    }
}

class Balances extends Component {
    constructor () {
        super();
        this.updateData = this.updateData.bind(this);

        this.state = { startDate: null, endDate: null };

        this.changeStartDate = this.changeStartDate.bind(this);
        this.changeEndDate = this.changeEndDate.bind(this);
    }

    componentDidMount () {
        this.updateData();
    }

    changeStartDate (newDate) {
        this.setState({ ...this.state, startDate: newDate });
    }

    changeEndDate (newDate) {
        this.setState({ ...this.state, endDate: newDate });
    }

    updateData () {
        const url = settings.api_server + "/purchaseDetail/";

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a list of bids (even if it's empty)
                this.props.setAllPurchases(data);
            }.bind(this));
    }

    render () {
        let data = this.props.purchases;

        if (this.state.startDate !== null) {
            const min = new Date(this.state.startDate);
            data = data.filter(purchase => new Date(purchase.date_of_purchase) >= min);
        }
        if (this.state.endDate !== null) {
            const max = new Date(this.state.endDate);
            data = data.filter(purchase => new Date(purchase.date_of_purchase) <= max);
        }

        return (
            <div>
                <AdminOnly />
                <PCANav isAdmin={this.props.isAdmin} />
                <Container>
                    <h1>Balances</h1>

                    <Row>
                        <Col><Datetime closeOnSelect value={this.state.startDate} onChange={this.changeStartDate} inputProps={{ placeholder: "Starting Date" }} dateFormat="YYYY-MM-DD" timeFormat={false} /></Col>
                        <Col><Datetime closeOnSelect value={this.state.endDate} onChange={this.changeEndDate} inputProps={{ placeholder: "Ending Date" }} dateFormat="YYYY-MM-DD" timeFormat={false} /></Col>
                    </Row>
                    <Row><Col>Results:</Col></Row>

                    {this.props.loaded ? <PurchasePager purchases={data} /> : "Loading..."}
                </Container>
                <PaymentModal refresh={this.updateData} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        purchases: state.allPurchases.purchases,
        loaded: state.allPurchases.loaded,
        lastUpdate: state.allPurchases.lastUpdate
    };
};

const mapDispatchToProps = dispatch => ({
    setAllPurchases: (p) => dispatch(setAllPurchases(p))
});

export default connect(mapStateToProps, mapDispatchToProps)(Balances);
