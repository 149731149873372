import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";

import {
    Row, Col,
    Button,
    Container,
} from "react-bootstrap";

import { resetTranslations, setTranslationList, fetchTranslations } from "../actions/translation_actions";
import AdminOnly from "../dashboard-components/AdminOnly";
import { LastSeen } from "../functions";
import Octicon, {Sync} from "@githubprimer/octicons-react";

import "../admin_pages/queues.css";

class TranslationRow extends Component {
    /*
    cohesive little row
    Needs to store the userID and avtoID, pass it.
    */
    constructor () {
        super();
        this.state = { data: null };
    }

    render () {
        let colour = null;
        let lock_text = null;

        // don't always have the lock data right away, could have been loaded
        // from somewhere else.
        if (this.props.data.lock && this.props.data.lock.locked_by_id !== null) {
            lock_text = "Locked by " + this.props.data.lock.locked_by;
            // if there is a lock, and it is not ours
            if (!this.props.data.lock.my_lock) { colour = "danger"; }
        }

        return <Row key={this.props.data.translation_id} variant={colour}>
            <Col md="5">{this.props.data.requesters.join(", ")}</Col>
            <Col md="4">{this.props.data.description}</Col>
            <Col md="3">{LastSeen(this.props.data.first_request)} {lock_text}</Col>
        </Row>;
    }
}

class TranslationQueue extends Component {
    // will be set to the timeout ID when we call setTimeout for auto-updates
    updateTimer = null;

    /**
     * @param {Object} props
     * @param {number} [props.updateInterval] The amount of time (ms) between
     * automatically re-fetching the data. If not provided, auto-updates won't
     * occur.
     * @param {number[]} [props.userWhitelist] If provided, only include
     * requests from users in this list. Otherwise, include all users.
     */
    constructor (props) {
        super(props);
        // if fetching, the refresh button is disabled
        this.state = { fetching: false };
        this.getData = this.getData.bind(this);
    }

    componentDidMount () {
        this.getData();
    }

    componentWillUnmount () {
        if (this.updateTimer) {
            clearTimeout(this.updateTimer);
            this.updateTimer = null;
        }
    }

    // fetch translation requests, set/reset auto-update timer
    getData(e) {
        // Since the whole box is a link to the page, need to prevent the link
        // being clicked when the refresh button is clicked. Use optional
        // chaining in case this function wasn't called due to a click.
        e?.preventDefault();

        // if already fetching, ignore
        if (this.state.fetching) {
            return;
        }
        // in case this was called for a reason besides a timeout, we'll cancel
        // any pending timeout before registering a new one
        if (this.updateTimer) {
            clearTimeout(this.updateTimer);
            this.updateTimer = null;
        }
        this.setState({...this.state, fetching: true});
        // provide a callback when fetching is complete, which resets the timer
        // and re-enables the refresh button
        fetchTranslations(() => {
            if ("updateInterval" in this.props) {
                this.updateTimer = setTimeout(this.getData, this.props.updateInterval);
            }
            this.setState({...this.state, fetching: false});
        });
    }

    render () {
        let trans = null;
        let numTrans = 0;
        if (!this.props.translationsLoaded) {
            trans = <Row><Col md="12">loading...</Col></Row>;
        }
        else {
            trans = this.props.translationList
                // only include if it's in the whitelist, or there's no whitelist
                .filter(x => {
                    const requesters = new Set(x.requester_ids);
                    return this.props.userWhitelist?.some(requesters.has, requesters) ?? true;
                })
                .map(x => <TranslationRow key={x.translation_id + "header"}
                    data={x}
                />);
            numTrans = trans.length;
            if (numTrans === 0) {
                trans = <Row><Col md="12">No translations found.</Col></Row>;
            }
        }

        return <a href="/#/all_translations" className="queueBoxLink">
            <Container className="whiteBackground dropShadow queueBox">
                <AdminOnly />
                <Row>
                    <Col>
                        <h2 className="greyTitle">Translations</h2>
                    </Col>
                    <Col md="2" className="text-end">
                        <h2 className="greyTitle">
                            {this.props.translationsLoaded &&
                                numTrans
                            }
                        </h2>
                    </Col>
                    <Col md="1" className="text-end" style={{padding: "0px"}}>
                        <Button size="sm"
                            onClick={this.getData}
                            style={{
                                marginRight: "5px",
                                marginTop: "5px"
                            }}
                        >
                            <Octicon icon={Sync}/>
                        </Button>
                    </Col>
                </Row>
                {trans}
            </Container>
        </a>;
    }
}

const mapStateToProps = state => {
    return {
        translationList: state.translations.translationList,
        translationsLoaded: state.translations.translationsLoaded,
    };
};

const mapDispatchToProps = dispatch => ({
    resetTranslations: () => dispatch(resetTranslations()),
    setTranslationList: (trans_list) => dispatch(setTranslationList(trans_list)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TranslationQueue);
