
const updateAmount = function (e) { // designed to be called by form onUpdate
    return {
        type: "UPDATE_BID_AMOUNT",
        amount: e.target.value
    };
};

const updateAmountNum = function (amount) { // takes a number instead of an event
    return {
        type: "UPDATE_BID_AMOUNT",
        amount
    };
};

const updateComment = function (e) { // designed to be called by form onUpdate
    return {
        type: "UPDATE_BID_COMMENT",
        comment: e.target.value
    };
};

const setUserModalBidData = function (amount, comment) {
    return {
        type: "SET_UPDATE_BID_DATA",
        amount,
        comment
    };
};

const clearBidData = function () { // can also be used for closing modals
    return {
        type: "CLEAR_BID_DATA"
    };
};

const showCreate = function () {
    return {
        type: "SHOW_CREATE_BID"
    };
};
const showCreateConfirm = function () {
    return {
        type: "SHOW_CREATE_CONFIRM_BID"
    };
};

const showUpdate = function () {
    return {
        type: "SHOW_UPDATE_BID"
    };
};
const showUpdateConfirm = function () {
    return {
        type: "SHOW_UPDATE_CONFIRM_BID"
    };
};

const setFetching = function () {
    return {
        type: "SET_BID_FETCHING"
    };
};
const clearFetching = function (message = null) {
    return {
        type: "CLEAR_BID_FETCHING",
        message
    };
};
const showConfirmCancel = function () {
    return {
        type: "SHOW_CONFIRM_CANCEL"
    };
};

export {
    updateAmount,
    updateAmountNum,
    updateComment,
    clearBidData,
    showCreate,
    showCreateConfirm,
    showUpdate,
    showUpdateConfirm,
    setFetching,
    clearFetching,
    showConfirmCancel,
    setUserModalBidData
};
