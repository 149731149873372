
import React, { Component } from "react";
import "./App.css";

import PCANav from "./dashboard-components/navbar";

import { connect } from "react-redux";

import { settings } from "./settings";

import {
    ListGroup,
    Container,
    Button,
    InputGroup,
    FormControl
} from "react-bootstrap";

import AddUser from "./admin_views/AddUser";

class AllUsers extends Component {
    constructor() {
        super();
        this.fetchUsers = this.fetchUsers.bind(this);
        this.handleNewUserClose = this.handleNewUserClose.bind(this);
        this.showAddUser = this.showAddUser.bind(this);

        this.state = { users: [], showNewUserModal: false, filter: "" };
    }

    componentDidMount() {
        // load all the question threads
        this.fetchUsers();
    }

    fetchUsers() {
        // get all users from backend
        const url = settings.api_server + "/user/getAllUsers";

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                this.setState({ ...this.state, users: data });
            }.bind(this));
    }

    handleNewUserClose() {
        this.setState({ ...this.state, showNewUserModal: false });
        // show the new user (maybe, just refresh)
        this.fetchUsers();
    }

    showAddUser() {
        this.setState({ ...this.state, showNewUserModal: true });
    }

    render() {
        // we have at least one user, so if there's none, it's loading
        if (this.state.users.length === 0) { return <p>Loading...</p>; }

        function hasData(user, substring){
            // if no data (empty string), filter nothing
            // includes is "has substring" of javascript. Who knew.
            // object looks like
            // {user_id: 3, username: "bob@mailinator.com", firstname: "Bob", lastname: "Smith", active: true}
            return substring === "" || user["username"].toLowerCase().includes(substring) || user["firstname"].toLowerCase().includes(substring) || user["lastname"].toLowerCase().includes(substring);
        }

        const filteredData = this.state.users.filter(user => hasData(user, this.state.filter.toLowerCase()));

        const userData = filteredData.map(x => {
            return (
                <ListGroup.Item action href={"#/user_detail/" + x.user_id}
                    id={x.user_id} key={x.user_id}>
                    {x.firstname} {x.lastname} {x.username}
                </ListGroup.Item>
            );
        });

        return (
            <Container className="wideContainer">
                <PCANav isAdmin={this.props.isAdmin} />
                <div className="whiteTitle">
                    All Users <Button onClick={this.showAddUser}>Add a user</Button>
                </div>
                <Container className="whiteBackground dropShadow wideContainer"
                    style={{ "paddingTop": "10px", "paddingBottom": "10px" }}>


                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Search contents:
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            autoComplete="off"
                            placeholder="Search value"
                            value={this.state.filter}
                            onChange={(e) => {
                                this.setState({...this.state, filter: e.target.value});
                            }}
                        />
                        <InputGroup.Append>
                            <Button
                                size="sm"
                                variant="outline-danger"
                                onClick={() => {
                                    this.setState({...this.state, filter: ""});
                                }}
                            >
                                Clear
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>


                    <ListGroup>
                        {userData}
                    </ListGroup>
                    <AddUser show={this.state.showNewUserModal} closeCallback={this.handleNewUserClose} />
                </Container>
            </Container>

        );
    }
}

const mapStateToProps = state => {
    return {
        isAdmin: state.profile.is_admin
    };
};

export default connect(mapStateToProps)(AllUsers);
