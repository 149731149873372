import React, { Component } from "react";
import { connect } from "react-redux";

import { settings } from "../../settings";

import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";

import { showCreate, setFetching, clearFetching, clearBidData } from "../../actions/user_bid_modal_actions";

import FxView from "../../widgets/FxView";
import commaNumber from "../../widgets/commaNumbers";

import { setMessage } from "../../actions/alert_actions";

/**
 * Note: state.user_bid_modal.amount is a value in yen or thousands of yen,
 * depending on whether we are updating a bid (individual yen) or creating a
 * bid (thousands of yen). The API endpoint for creating bids multiplies the
 * amount by 1000, so the amount should always be sent to the backend as-is.
 */

class CreateBidConfirmModal extends Component {
    constructor () {
        super();
        this.placeBid = this.placeBid.bind(this);
    }

    placeBid () {
        const url = settings.api_server + "/bids/bid/" + this.props.id;
        const postData = { amount: this.props.amount, comment: this.props.comment };
        this.props.setFetching();

        fetch(url, {
            method: "POST",
            body: JSON.stringify(postData),
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // refresh data
                if (data.success) {
                    this.props.setMessage("Set bid for " + this.props.amount + "000 Yen");
                    this.props.fetchBid(); // update underlying component
                    this.props.clearBidData(); // clean up
                } else {
                    // set the message
                    this.props.clearFetching(data.message);
                    // go back to the bid screen
                    this.props.showCreate();
                }
            }.bind(this));
    }

    render () {
        return (
            <Modal size='lg' show={this.props.show} onHide={this.props.clearBidData}>
                <Modal.Header>Bid on this car</Modal.Header>
                <Modal.Body>
                    {this.props.message !== null && <Alert variant="info">{this.props.message}</Alert>}

                    {this.props.show && <p>Are you sure you want to bid {commaNumber(parseInt(this.props.amount, 10) * 1000)} JPY, which is about <FxView amount={this.props.amount} thousands={true} />?</p>}

                Reminder! Your bid amount is only PART of your invoice.  <a target="_blank" href={"https://www.pacificcoastjdm.com/price"} rel="noreferrer">See our fee structure.</a>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={this.props.fetching} onClick={this.placeBid}>Place bid</Button> <Button disabled={this.props.fetching} onClick={this.props.showCreate}>Close without bidding</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        amount: state.user_bid_modal.amount,
        comment: state.user_bid_modal.comment,
        fetching: state.user_bid_modal.fetching,
        message: state.user_bid_modal.message,
        show: state.user_bid_modal.showConfirmBid,
        fxLoaded: state.fx.loading, // fx data
        fx_data: state.fx.fx_data
    };
};

const mapDispatchToProps = dispatch => ({
    clearBidData: () => dispatch(clearBidData()), // for closing the modal
    showCreate: () => dispatch(showCreate()),
    setFetching: () => dispatch(setFetching()),
    clearFetching: (msg) => dispatch(clearFetching(msg)),
    setMessage: (msg) => dispatch(setMessage(msg))

});

export default connect(mapStateToProps, mapDispatchToProps)(CreateBidConfirmModal);
