import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Alert,
    Form,
    Button,
    Modal,
    FormLabel,
    FormControl
} from "react-bootstrap";

import { settings } from "../../settings";

import {
    fetch_invoice,
    hideAddLineItemModal
} from "../../actions/invoice_actions";

import { TitleHelp } from "../../widgets/mouseovers";
import MoneyInput from "../../widgets/MoneyInput";
import Octicon, { Alert as AlertIcon } from "@githubprimer/octicons-react";

const selectOptions = [<option key={1} value="Payment">Payment</option>,
    <option key={2} value="Credit">Credit</option>,
    <option key={3} value="Car Parts">Car Parts</option>,
    <option key={4} value="Service Fee">Service Fee</option>,
    <option key={5} value="Freight">Freight</option>,
    <option key={6} value="Inland Transport">Inland Transport</option>,
    <option key={7} value="Insurance">Insurance</option>,
    <option key={10} value="Other...">Other...</option>];

class AddLineItem extends Component {
    constructor () {
        super();
        this.dropdownchange = this.dropdownchange.bind(this);
        this.addInvoiceData = this.addInvoiceData.bind(this);
        this.clearAmount = this.clearAmount.bind(this);

        // refs to form elements
        this.item_type = null;
        this.item_type_other = null;
        this.detail = null;

        this.state = {
            // use a custom item type?
            invoiceOther: false,
            // error message
            message: null,
            // amount for the new item, will be set to a number once a valid
            // value is entered (not using null because using null as the value
            // of a controlled component generates a warning)
            amount: "",
        };
    }

    addInvoiceData () {
        const url = settings.api_server + "/invoice/admin/purchase/" + this.props.purchase_id + "/" + this.props.invoice_id + "/lineItem";

        const postdata = {
            item_type: this.item_type.value,
            // if an amount was entered, this will be a number, but the backend
            // expects a string
            amount: this.state.amount.toString(),
            detail: this.detail.value
        };

        if (this.state.invoiceOther) {
            postdata.item_type = this.item_type_other.value;
        }

        fetch(url, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(postdata),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(data => data.success ?
                Promise.resolve() // no additional data for successful results
                : Promise.reject(data.message)
            )
            .then(
                // fetch the new data, close the modal
                () => {
                    fetch_invoice(this.props.purchase_id, this.props.invoice_id);
                    this.props.hideAddLineItemModal();
                    this.setState({ ...this.state, message: null });
                },
                reason => {
                    this.setState({ ...this.state, message: reason });
                }
            );
    }

    dropdownchange () {
        const newState = this.state;
        newState.invoiceOther = this.item_type.value === "Other...";
        this.setState(newState);
    }

    // reset the new item's amount to an empty string, clearing the text input
    // for the next time we create an item
    clearAmount() {
        this.setState({...this.state, amount: ""});
    }

    render () {
        return <Modal
            show={this.props.showAddLineItemModal}
            onHide={this.props.hideAddLineItemModal}
            onShow={this.clearAmount}
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Line Item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.state.message !== null && <Alert>{this.state.message}</Alert>}
                <Form>
                    <Form.Group>
                        <FormLabel><b>What Type of Line item</b><br />The item will go under this header on the invoice</FormLabel>
                        <FormControl as="select" placeholder="select" ref={ref => { this.item_type = ref; }} onChange={this.dropdownchange}>
                            {selectOptions}
                        </FormControl>
                    </Form.Group>
                    {this.state.invoiceOther && <div>
                        <Form.Group>
                            <FormLabel>Title for section<TitleHelp /></FormLabel>
                            <FormControl type="text" ref={ref => { this.item_type_other = ref; }} /> </Form.Group></div>
                    }
                    <Form.Group>
                        <FormLabel><b>Line item description</b><br/>This is the text on the line for the item</FormLabel>
                        <FormControl type="text" ref={ref => { this.detail = ref; }} />
                    </Form.Group>

                    <Form.Group>
                        <FormLabel>
                            <b>Line item amount</b><br/>
                            <Octicon icon={AlertIcon}/> Amount must be <em>negative</em> for payments!
                        </FormLabel>
                        <MoneyInput
                            value={this.state.amount}
                            onChange={v => this.setState({...this.state, amount: v})}
                        />
                    </Form.Group>
                </Form>
                <p>Note: "Freight" and "Insurance" line items do not show up on FOB cost. All others do.</p>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.addInvoiceData}>Save and close</Button> <Button onClick={this.props.hideAddLineItemModal}>Close without saving</Button>
            </Modal.Footer>

        </Modal>;
    }
}
const invoiceEditMapStateToProps = state => {
    return {
        purchase_id: state.invoice.purchase_id,
        invoice_id: state.invoice.add_line_item_invoice_id,
        invoice_data: state.invoice.invoice_data,
        showAddLineItemModal: state.invoice.showAddLineItemModal

    };
};

const invoiceEditMapDispatchToProps = dispatch => ({
    hideAddLineItemModal: () => dispatch(hideAddLineItemModal())
});

export default connect(invoiceEditMapStateToProps, invoiceEditMapDispatchToProps)(AddLineItem);
