import React, { Component } from "react";
import PropTypes from "prop-types";

import "../App.css";
import { connect } from "react-redux";

import {
    Container,
    Row, Col,
    Card, Table,
} from "react-bootstrap";

export class TranslationData extends Component {

    constructor(){
        super();
        this.printMain = this.printMain.bind(this);
    }

    printMain() {
        let result = [];
        try {
            let dict = JSON.parse(this.props.main);
            for (let x in dict) {
                result.push(<tr key={x}><td>{x}</td><td>{dict[x]}</td></tr>);
            }
        }
        catch (err) {
            // we'll ignore
            console.log("Bad JSON in response");
        }
        return result;
    }
    render() {
        return (
            <Card >
                <Card.Header>Translation</Card.Header>
                <Card.Body className="whitespace flush_card">
                    <Container>
                        <Table striped bordered size="sm" hover className="left-align-table">
                            <tbody>

                                {this.printMain()}

                                {this.props.notes !== "" &&
                                    <tr>
                                        <td>Notes</td>
                                        <td>{this.props.notes}</td>
                                    </tr>
                                }
                                {this.props.sales_points &&
                                    <tr>
                                        <td>Sales points</td>
                                        <td>{this.props.sales_points}</td>
                                    </tr>
                                }
                                {this.props.report &&
                                    <tr>
                                        <td>Report</td>
                                        <td>{this.props.report}</td>
                                    </tr>
                                }
                                {this.props.diagram_notes &&
                                    <tr>
                                        <td>Diagram notes</td>
                                        <td>{this.props.diagram_notes}</td>
                                    </tr>
                                }
                                {this.props.exporter_notes &&
                                    <tr>
                                        <td>Exporter notes</td>
                                        <td>{this.props.exporter_notes}</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>

                    </Container>
                </Card.Body>
            </Card>
        );
    }
}
TranslationData.propTypes = {
    main: PropTypes.object.isRequired,
    notes: PropTypes.string.isRequired,
    sales_points: PropTypes.string.isRequired,
    report: PropTypes.string.isRequired,
    diagram_notes: PropTypes.string.isRequired,
    exporter_notes: PropTypes.string.isRequired
};

class Translation extends Component {

    render() {

        if (this.props.failure)
            return null;
        if (this.props.loaded && this.props.translation !== null && this.props.requested_translation && this.props.translation_completed)
            return <Row>
                <Col md={12}>
                    <TranslationData
                        main={this.props.translation.main}
                        notes={this.props.translation.notes}
                        sales_points={this.props.translation.sales_points}
                        report={this.props.translation.report}
                        diagram_notes={this.props.translation.diagram_notes}
                        exporter_notes={this.props.translation.exporter_notes}
                    />
                </Col>
            </Row>;
        return null;
    }
}


const mapStateToProps = state => {
    return {
        loaded: state.auction_car.translation_loaded,
        requested_translation: state.auction_car.requested_translation,
        translation: state.auction_car.translation,
        failure: state.auction_car.failure,
        translation_completed: state.auction_car.translation_completed,
    };
};


export default connect(mapStateToProps)(Translation);
