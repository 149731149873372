import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Commas from "../widgets/commaNumbers";

import { settings } from "../settings";

import {
    Form,
    OverlayTrigger,
    Tooltip,
    Alert,
    Button,
    Modal,
    FormControl,
    ListGroup,
    Table,
    FormLabel,
} from "react-bootstrap";
import {
    fetch_invoice
} from "../actions/invoice_actions";

import Octicon, {
    Question,
    Unverified,
    Alert as AlertIcon,
} from "@githubprimer/octicons-react";
import MoneyInput from "../widgets/MoneyInput";

// Variations of last row which displays the total balance
// "right" -> combine item name and description cells, right-justify 'Total'
// other -> don't combine cells, 'Total' is under item name, desc. is blank
const TOTAL_ROW_STYLE = "right";

const display_order_info = (
    <Tooltip id="tooltip">Controls the order of how the line items will appear on the invoice. Higher numbers are placed after lower numbers. There can be gaps between the numbers (like 1, 10, 100).</Tooltip>
);

export class DisplayOrder extends Component {
    render() {
        return <OverlayTrigger placement="right-start" overlay={display_order_info}><span><Octicon icon={Unverified} /></span></OverlayTrigger>;
    }
}

class ShowBalanceEditableComponent extends Component {
    constructor () {
        super();
        this.deleteHandler = this.deleteHandler.bind(this);
        this.closeConfirm = this.closeConfirm.bind(this);
        this.showConfirm = this.showConfirm.bind(this);
        this.showEdit = this.showEdit.bind(this);
        this.closeEdit = this.closeEdit.bind(this);
        this.saveEdit = this.saveEdit.bind(this);
        this.editAmount = this.editAmount.bind(this);
        this.editDetail = this.editDetail.bind(this);
        this.editDisplayOrder = this.editDisplayOrder.bind(this);
        this.toggleLineItem = this.toggleLineItem.bind(this);
        this.state = {
            deleteItem: -1,
            editItem: -1,
            newAmount: "",
            newDetail: "",
            message: null,
            displayOrder: ""
        };

        this.editName = "";
    }

    deleteHandler () {
        const url = settings.api_server + "/invoice/admin/purchase/" + this.props.purchase_id + "/" + this.props.invoice_id + "/lineItem/" + this.state.deleteItem;

        fetch(url, {
            credentials: "include",
            method: "DELETE",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function () {
                // refresh the underlying data
                fetch_invoice(this.props.purchase_id, this.props.invoice_id);
                this.closeConfirm();
            }.bind(this));
    }

    saveEdit () {
        const url = settings.api_server + "/invoice/admin/purchase/" + this.props.purchase_id + "/" + this.props.invoice_id + "/lineItem/" + this.state.editItem;

        // name change stuff is vestigial, or for future. Who knows.
        const postData = {
            amount: this.state.newAmount,
            item_type: this.editName,
            detail: this.state.newDetail,
            order: this.state.displayOrder
        };
        fetch(url, {
            credentials: "include",
            body: JSON.stringify(postData),
            method: "PUT",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(data => data.success ?
                Promise.resolve() // no additional data for successful results
                : Promise.reject(data.message)
            )
            .then(
                // refresh the underlying data
                () => {
                    fetch_invoice(this.props.purchase_id, this.props.invoice_id);
                    this.closeConfirm();
                    this.setState({ ...this.state, message: null });
                    this.closeEdit();
                },
                reason => {
                    this.setState({ ...this.state, message: reason });
                }
            );
    }

    showConfirm (e) {
        const newState = this.state;
        newState.deleteItem = parseInt(e.target.id, 10);
        this.setState(newState);
    }

    showEdit (e) {
    // find the old amount
        let oldAmount = 0;
        const invID = parseInt(e.target.id, 10);
        let oldDetail = "";
        let oldDisplayOrder;
        const line_items = this.props.invoice_data[this.props.invoice_id];
        for (let i = 0; i < line_items.length; i++) {
            if (line_items[i].line_item_id === invID) {
                oldAmount = line_items[i].amount;
                oldDetail = line_items[i].detail;
                oldDisplayOrder = line_items[i].display_order;
            }
        }

        this.setState({ ...this.state, editItem: invID, newAmount: oldAmount, newDetail: oldDetail, displayOrder: oldDisplayOrder });
    }

    editAmount (amount) {
        this.setState({ ...this.state, newAmount: amount });
    }

    editDetail (e) {
        this.setState({ ...this.state, newDetail: e.target.value });
    }

    editDisplayOrder (e) {
        this.setState({ ...this.state, displayOrder: e.target.value });
    }

    closeEdit () {
        const newState = this.state;
        newState.editItem = -1;
        this.setState(newState);
    }

    closeConfirm () {
        const newState = this.state;
        newState.deleteItem = -1;
        this.setState(newState);
    }

    // send line item ID and whether to include it to our parent component
    toggleLineItem(e) {
        this.props.toggle_include_line_item(e.target.value, e.target.checked);
    }

    // return a table row for the given line item
    lineItemRow(lineItem) {
        // controls are only enabled if invoice isn't closed
        const controls = this.props.complete ?
            <></>
            :
            <>
                <td>
                    <Button variant="warning" size="sm" onClick={this.showEdit} id={lineItem.line_item_id}>
                        Edit
                    </Button>
                </td>
                <td>
                    <Button variant="danger" size="sm" onClick={this.showConfirm} id={lineItem.line_item_id}>
                        Delete
                    </Button>
                </td>
            </>;

        return <tr key={"line_item_row_" + lineItem.line_item_id}>
            {/* name */}
            <td style={{textAlign: "justify", textAlignLast: "left"}}>{lineItem.item_type}</td>

            {/* desc */}
            <td style={{textAlign: "justify", textAlignLast: "left"}}>{lineItem.detail}</td>

            {/* amount -- textAlign='right' doesn't right-justify the content,
            but textAlignLast does. According to w3schools, textAlignLast only
            works on older versions of MS Edge if textAlign is set to 'justify'
            https://www.w3schools.com/cssref/css3_pr_text-align-last.php */}
            <td style={{textAlign: "justify", textAlignLast: "right"}}>
                {Commas(lineItem.amount)}
            </td>

            {/* edit and delete controls */}
            {controls}

            {/* Checkbox to toggle including in custom invoice */}
            <td>
                <Form.Check id={"include_in_invoice_" + lineItem.line_item_id}
                    type="checkbox" defaultChecked={false}
                    value={lineItem.line_item_id}
                    onChange={this.toggleLineItem}
                    inline
                    style={{marginRight: "auto", marginLeft: "auto", left: "2px"}}
                    aria-label="Include in custom invoice"
                />
            </td>
        </tr>;
    }

    // Return the table displaying all line items and the total.
    lineItemsTable() {
        const line_items = this.props.invoice_data[this.props.invoice_id];

        const total = line_items.reduce((sum, currItem) => sum + currItem.amount, 0);

        // two variations of the first two cells of the row which displays the
        // total, see declaration of TOTAL_ROW_STYLE for details
        const totalCells = TOTAL_ROW_STYLE == "right" ?
            <>
                <td colSpan="2" style={{textAlign: "justify", textAlignLast: "right"}}>
                    <b>Total</b>
                </td>
            </>
            :
            <>
                <td>
                    <b>Total</b>
                </td>
                <td/>
            </>;

        const controlHeaders = this.props.complete ?
            <></>
            :
            <>
                <th style={{width: "1px"}}></th>
                <th style={{width: "1px"}}></th>
            </>;

        return <Table striped bordered size="sm">
            <thead>
                <tr>
                    <th style={{width: "12em"}}>Item name</th>
                    <th>Description</th>
                    <th style={{width: "1px"}}>Balance</th>
                    {controlHeaders}
                    <th style={{width: "1px"}}>
                        <OverlayTrigger overlay={
                            <Tooltip>Select to include in Custom Invoice PDF</Tooltip>
                        }>
                            <span><Octicon icon={Question}/></span>
                        </OverlayTrigger>
                    </th>
                </tr>
            </thead>
            <tbody>
                {/* Table rows for each line item */}
                {line_items.map(x => this.lineItemRow(x))}
                {/* Last row: total balance */}
                <tr>
                    {totalCells}
                    <td style={{textAlign: "justify", textAlignLast: "right"}}>
                        {Commas(total)}
                    </td>
                    {this.props.complete ? <td/> : <td colSpan="3"/>}
                </tr>
            </tbody>
        </Table>;
    }

    // return the ListGroup Item displayed when there are no line items
    emptyInvoiceMessage() {
        return <ListGroup.Item style={{backgroundColor: "#eee"}}>
            No items in invoice
        </ListGroup.Item>;
    }

    render () {
        if (!(this.props.invoice_id in this.props.invoice_data)) { return null; }

        const line_items = this.props.invoice_data[this.props.invoice_id];
        let confirmAmount = 0;

        const showDeleteConfirm = this.state.deleteItem !== -1;
        const showEditItem = this.state.editItem !== -1;
        if (showDeleteConfirm || showEditItem) {
            // get data for the modal for editing or deleting
            const thisInvoice = this.props.invoice_data[this.props.invoice_id];
            for (let i = 0; i < thisInvoice.length; i++) {
                if (thisInvoice[i].line_item_id === this.state.deleteItem || thisInvoice[i].line_item_id === this.state.editItem) {
                    this.editName = thisInvoice[i].item_type;
                    confirmAmount = thisInvoice[i].amount;
                }
            }
        }

        return (
            <ListGroup id={this.props.last_invoice_update}>
                {/* Table with line items, or a message when no items are added */}
                {line_items.length > 0 ? this.lineItemsTable() : this.emptyInvoiceMessage()}

                <Modal
                    show={showDeleteConfirm}
                    onHide={this.closeConfirm}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Delete {this.editName} (&yen;{Commas(confirmAmount)})?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-danger" onClick={this.deleteHandler}>Yes, delete</Button>
                        <Button variant="outline-primary" onClick={this.closeConfirm}>No, leave it</Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showEditItem}
                    onHide={this.closeEdit}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit {this.editName}?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.message !== null && <Alert>{this.state.message}</Alert>}
                        <Form>
                            <Form.Group>
                                <FormLabel><b>Line item description</b></FormLabel>
                                <FormControl type="text" value={this.state.newDetail} onChange={this.editDetail}/>
                            </Form.Group>
                            <Form.Group>
                                <FormLabel>
                                    <b>Line item amount</b><br/>
                                    <Octicon icon={AlertIcon}/> Amount must be <em>negative</em> for payments!
                                </FormLabel>
                                <MoneyInput
                                    value={this.state.newAmount}
                                    onChange={this.editAmount}
                                />
                            </Form.Group>
                            <Form.Group>
                                <FormLabel><b>Display order: <DisplayOrder/></b></FormLabel>
                                <FormControl type="text" value={this.state.displayOrder} onChange={this.editDisplayOrder}/>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={this.saveEdit}>Save edit</Button>
                        <Button variant="outline-warning" onClick={this.closeEdit}>Discard edit</Button>
                    </Modal.Footer>
                </Modal>
            </ListGroup>
        );
    }
}
ShowBalanceEditableComponent.propTypes = {
    invoice_id: PropTypes.string.isRequired,
    toggle_include_line_item: PropTypes.func.isRequired
};
const showInvoiceMapStateToProps = state => {
    return {
        purchase_id: state.invoice.purchase_id,
        invoice_data: state.invoice.invoice_data,
        last_invoice_update: state.invoice.last_invoice_update
    };
};

export default connect(showInvoiceMapStateToProps)(ShowBalanceEditableComponent);
