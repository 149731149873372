import React from "react";
import {
    Container, Row, Col,
} from "react-bootstrap";
import { settings } from "../settings";

import CarAssignQuiz from "./CarAssignQuiz";
import CarPendingQuizAdmin from "./CarPendingQuizAdmin";
import CarCompletedQuizzes from "./CarCompletedQuizzes";

/**
 * Returns the controls for the Edit Purchase page which are used to assign new
 * quizzes, bypass or remove pending quizzes, or view completed quizzes.
 * @param {Object} props
 * @param {string} props.purchase_id
 * @returns 
 */
export default function QuizAdminControls({purchase_id})
{
    const [pendingQuiz, setPendingQuiz] = React.useState(null);
    const [pendingError, setPendingError] = React.useState(null);

    const reloadPendingQuiz = React.useCallback(() => {
        const url = settings.api_server + "/quiz/admin/" + purchase_id;
        // if component is unmounted or purchase changes before response
        // arrives, cancel setting the quiz/error.
        let cancelled = false;
        fetch(url, {credentials: "include"})
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(data => data.success ?
                data.template
                : Promise.reject(data.message)
            )
            .then(
                quizTemplate => {
                    if (!cancelled) {
                        setPendingQuiz(quizTemplate);
                        setPendingError(null);
                    }
                },
                reason => {
                    if (!cancelled) {
                        setPendingQuiz(null);
                        setPendingError("Error: " + reason);
                    }
                }
            );
        return () => {cancelled = true;};
    }, [purchase_id]);

    // always reload when we first mount and when the purchase changes
    React.useEffect(reloadPendingQuiz, [purchase_id]);

    // Only show a pending quiz if one exists (or there's an error to display),
    // otherwise don't include the field.
    let pendingField = null;
    if (pendingError)
    {
        pendingField = <div>
            <b>Error loading pending quiz: </b>{pendingError}
        </div>;
    }
    else if (pendingQuiz)
    {
        pendingField = <div>
            <b>Quiz required: </b>
            <CarPendingQuizAdmin
                purchase_id={purchase_id}
                quiz={pendingQuiz}
                updateCallback={reloadPendingQuiz}
            />
        </div>;
    }

    return <Container>
        <Row>
            <Col>
                <h3>Quizzes <CarAssignQuiz
                    purchase_id={purchase_id}
                    pendingQuiz={pendingQuiz}
                    updateCallback={reloadPendingQuiz}
                /></h3>
                {pendingField}
                <div>
                    <b>Completed quizzes: </b>
                    <CarCompletedQuizzes purchase_id={purchase_id}/>
                </div>
            </Col>
        </Row>
    </Container>;
}
