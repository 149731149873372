import React, { Component } from "react";
import PropTypes from "prop-types";
import { settings } from "../../settings";

import {
    Button
} from "react-bootstrap";

export default class ConfirmButton extends Component {
    constructor () {
        super();
        this.clicked = this.clicked.bind(this);
        this.state = { working: false };
    }

    clicked (e) {
        e.stopPropagation();
        // Since we're part of the Bids request monitoring card, we're inside a
        // link to the page. We need to prevent that link from being clicked.
        e.preventDefault();

        const url = settings.api_server + "/bids/admin/" + this.props.bid_id + "/confirm";

        this.setState({ ...this.state, working: true });

        fetch(url, {
            credentials: "include",
            method: "PUT",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function () {
                // refresh
                if (this.props.refresh !== null) {
                    this.props.refresh();
                }
                this.setState({ ...this.state, working: false });
            }.bind(this)
            );
    }

    render () {
        return <Button
            size="sm"
            variant="outline-danger"
            onClick={this.clicked}
            disabled={this.props.confirmed || this.state.working}>
            {this.props.confirmed ? "Confirmed" : "Confirm"}
        </Button>;
    }
}

ConfirmButton.propTypes = {
    bid_id: PropTypes.number.isRequired,
    confirmed: PropTypes.bool.isRequired,
    refresh: PropTypes.func.isRequired // can be null!
};
