/**
 * Strips any commas from the given string, and returns the result as a number.
 * If the string contains non-comma characters that result in it not being a
 * valid number, this function returns NaN.
 * @param {string} str
 * @returns {number}
 */
export function stripCommas(str)
{
    // string has a length and integer keys, so we can use Array.filter
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/filter#calling_filter_on_non-array_objects
    return Number(Array.prototype.filter.call(str, c => c !== ",").join(""));
}
