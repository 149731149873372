import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Col, Row,
    Alert,
    Button,
    Modal,
    FormGroup,
    FormLabel,
    FormControl
} from "react-bootstrap";

import { setUserModalBidData, updateAmountNum, updateComment, clearBidData, showUpdateConfirm, showConfirmCancel } from "../../actions/user_bid_modal_actions";

import MoneyInput from "../../widgets/MoneyInput";
import BidLimits from "../../dashboard-components/BidLimits";
import FxView from "../../widgets/FxView";

/**
 * Note: state.user_bid_modal.amount is a value in yen or thousands of yen,
 * depending on whether we are updating a bid (individual yen) or creating a
 * bid (thousands of yen). The API endpoint for creating bids multiplies the
 * amount by 1000, so the amount should always be sent to the backend as-is.
 */

class UpdateBidModal extends Component {
    constructor () {
        super();
        this.loadOrig = this.loadOrig.bind(this);
    }

    loadOrig () {
        this.props.setUserModalBidData(this.props.origamount, this.props.origcomment);
    }

    render () {
        return (
            <Modal size='lg' show={this.props.show} onEntering={this.loadOrig} onHide={this.props.clearBidData}>
                <Modal.Header>Update your bid</Modal.Header>
                <Modal.Body>
                    <BidLimits showLimitList={false}/>
                    {this.props.message !== null && <Alert>{this.props.message}</Alert>}
                    What would you like to change your bid to?

                    <FormGroup className="alignCentre" as={Row} controlId="amount">
                        <Col sm={2} md={2}>
                            <FormLabel>Amount JPY</FormLabel>
                        </Col>
                        <Col sm={4} md={4} lg={3}>
                            {/* Enforce increments of 1,000 yen. Since the
                            variable represents individual yen for updating
                            existing bids, divide the amount displayed by 1000
                            and multiply the amount stored by 1000. */}
                            <MoneyInput
                                className="rightAlignTextbox"
                                placeholder="Amount"
                                value={Math.trunc(this.props.amount / 1000)}
                                onChange={(amt) => this.props.updateAmountNum(amt * 1000)}
                                thousands
                            />
                        </Col>
                        <Col sm={6} md={6} lg={7}>
                            {/* Don't display if empty or zero */}
                            {this.props.amount > 0 && <span>Approximately <FxView amount={this.props.amount} thousands={false} /></span>}
                        </Col>

                    </FormGroup>

                    <FormGroup as={Row} controlId="comment">
                        <Col sm={2} md={2}>
                            <FormLabel>Comment</FormLabel>
                        </Col>
                        <Col sm={10} md={10}>
                            <FormControl as="textarea" placeholder="Optional" value={this.props.comment} onChange={this.props.updateComment} />
                        </Col>
                    </FormGroup>

                    Reminder! Your bid amount is only PART of your invoice.  <a target="_blank" href={"https://www.pacificcoastjdm.com/price"} rel="noreferrer">See our fee structure.</a>

                    <BidLimits showUserLimit={false}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={this.props.fetching}
                        onClick={this.props.showConfirmCancel}
                    >
                        Cancel bid
                    </Button>
                    {/* Don't allow proceeding further if amount is empty */}
                    <Button
                        disabled={!this.props.amount || this.props.fetching}
                        onClick={this.props.showUpdateConfirm}
                    >
                        Update bid
                    </Button>
                    <Button
                        disabled={this.props.fetching}
                        onClick={this.props.clearBidData}
                    >
                        Close without changes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        amount: state.user_bid_modal.amount,
        comment: state.user_bid_modal.comment,
        fetching: state.user_bid_modal.fetching,
        message: state.user_bid_modal.message,
        show: state.user_bid_modal.showUpdate,

        origamount: state.auction_car.bid_amount,
        origcomment: state.auction_car.comment,

        fxLoaded: state.fx.loading, // fx data
        fx_data: state.fx.fx_data
    };
};

const mapDispatchToProps = dispatch => ({
    updateAmountNum: (n) => dispatch(updateAmountNum(n)),
    updateComment: (e) => dispatch(updateComment(e)),
    clearBidData: () => dispatch(clearBidData()), // for closing the modal
    showUpdateConfirm: () => dispatch(showUpdateConfirm()),
    showConfirmCancel: () => dispatch(showConfirmCancel()),
    setUserModalBidData: (amount, comment) => dispatch(setUserModalBidData(amount, comment))

});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBidModal);
