import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";

import {
    Container,
    Row, Col,
    Button
} from "react-bootstrap";

import {
    setRequestList,
    clearRequestList,
} from "../actions/image_request_list_actions";

import AdminOnly from "../dashboard-components/AdminOnly";

import { settings } from "../settings";
import Octicon, {Sync} from "@githubprimer/octicons-react";

import "../admin_pages/queues.css";

class ImageRequestRowComponent extends Component {
    /*
  cohesive little row
  Needs to store the userID and avtoID, pass it.
  */
    constructor () {
        super();
        this.state = { data: null };
    }

    render () {
        const key = this.props.data.vehicle_id + "_" + this.props.data.requester_id;

        const requesters = this.props.data.requesters.map(u => u.firstname + " " + u.lastname).join(", ");
        let colour = null;
        let lock_text = null;

        if (this.props.data.lock.locked_by_id !== null) {
            lock_text = "Locked by " + this.props.data.lock.locked_by;
            // if there is a lock, and it is not ours
            if (!this.props.data.lock.my_lock) { colour = "danger"; }
        }
        return <Row key={key} variant={colour}>
            <Col md="6">{this.props.data.car_data}</Col><Col md="4">{requesters}</Col><Col md="2">{lock_text}</Col>
        </Row>;
    }
}
const mapStateToPropsRow = state => {
    return {
        request_list: state.image_request_list.current_image_list,
        request_list_loaded: state.image_request_list.request_list_loaded,
    };
};

const mapDispatchToPropsRow = dispatch => ({
    setRequestList: (list) => dispatch(setRequestList(list)),
});

const ImageRequestRow = connect(mapStateToPropsRow, mapDispatchToPropsRow)(ImageRequestRowComponent);

class ImageRequestQueue extends Component {
    // will be set to the timeout ID when we call setTimeout for auto-updates
    updateTimer = null;

    /**
     * @param {Object} props
     * @param {number} [props.updateInterval] The amount of time (ms) between
     * automatically re-fetching the data. If not provided, auto-updates won't
     * occur.
     * @param {number[]} [props.userWhitelist] If provided, only include
     * requests from users in this list. Otherwise, include all users.
     */
    constructor (props) {
        super(props);
        // if fetching, the refresh button is disabled
        this.state = {fetching: false};
        this.getData = this.getData.bind(this);
    }

    componentDidMount () {
        this.getData();
    }

    componentWillUnmount () {
        this.props.clearRequestList();
        if (this.updateTimer) {
            clearTimeout(this.updateTimer);
            this.updateTimer = null;
        }
    }

    // update the list of requests, set a timeout to auto-update
    getData (e) {
        // Since the whole box is a link to the page, need to prevent the link
        // being clicked when the refresh button is clicked. Use optional
        // chaining in case this function wasn't called due to a click.
        e?.preventDefault();

        // if already fetching, ignore
        if (this.state.fetching) {
            return;
        }
        // in case this was called for a reason besides a timeout, we'll cancel
        // any pending timeout before registering a new one
        if (this.updateTimer) {
            clearTimeout(this.updateTimer);
            this.updateTimer = null;
        }

        this.setState({...this.state, fetching: true});

        const url = settings.api_server + "/image_requests/queue";

        fetch(url, {
            method: "GET",
            credentials: "include"
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // refresh data
                this.props.setRequestList(data);
                if ("updateInterval" in this.props) {
                    this.updateTimer = setTimeout(this.getData, this.props.updateInterval);
                }
                this.setState({...this.state, fetching: false});
            }.bind(this));
    }

    render () {
        let images = null;
        let numImages = 0;
        if (!this.props.request_list_loaded) {
            images = <Row><Col md="12">Loading...</Col></Row>;
        }
        else {
            images = this.props.request_list
                // only include if it's in the whitelist, or there's no whitelist
                .filter(x => this.props.userWhitelist?.includes(x.requester_id) ?? true)
                .map(x => <ImageRequestRow key={x.vehicle_id + "_" + x.requester_id} data={x} />);
            numImages = images.length;
            if (numImages === 0) {
                images = <Row><Col md="12">No image requests found.</Col></Row>;
            }
        }

        return <a href="/#/ImageRequests" className="queueBoxLink">
            <Container className="whiteBackground dropShadow queueBox">
                <AdminOnly />
                <Row>
                    <Col>
                        <h2 className="greyTitle">Images</h2>
                    </Col>
                    <Col md="2" className="text-end">
                        <h2 className="greyTitle">
                            {this.props.request_list_loaded &&
                                numImages
                            }
                        </h2>
                    </Col>
                    <Col md="1" className="text-end" style={{padding: "0px"}}>
                        {this.props.request_list_loaded &&
                            <Button size="sm"
                                onClick={this.getData}
                                style={{
                                    marginRight: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <Octicon icon={Sync}/>
                            </Button>
                        }
                    </Col>
                </Row>
                {images}
            </Container>
        </a>;
    }
}

const mapStateToProps = state => {
    return {
        request_list: state.image_request_list.request_list,
        request_list_loaded: state.image_request_list.request_list_loaded,
    };
};

const mapDispatchToProps = dispatch => ({
    setRequestList: (list) => dispatch(setRequestList(list)),
    clearRequestList: () => dispatch(clearRequestList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageRequestQueue);
