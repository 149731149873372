/**
 * This is the top-level file for the Task Manager page. It contains a table of
 * tasks with a pager, as well as a panel for filtering results.
 */

import React, { Component } from "react";
import "./App.css";

import PCANav from "./dashboard-components/navbar";
import DashNav from "./dashboard-components/DashNav";

import {
    Container, Row, Col, Table, Spinner
} from "react-bootstrap";

import TaskPanel from "./task_manager_views/TaskPanel";
import TaskRow from "./task_manager_views/TaskRow";

import {settings} from "./settings";

import {isTodayTZ, isTomorrowTZ} from "./functions";

// Columns in the table, especially important so we know the total number of
// columns if we want to render something spanning all columns.
const TABLE_COLS = [
    "Auction",
    "Lot",
    "Vehicle",
    "Image",
    "Image Request",
    "Translation Request",
    "Bid Request",
    "Questions",
    "Bid Amount",
    "Result",
    "Sold / Unsold",
    "Auction Time",
    null, // delete buttons, no column label
];
const NUM_TABLE_COLS = TABLE_COLS.length;

class TaskManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tasks: null,
            filters: {
                time: null,
                newImages: false,
                newTranslations: false,
                newQuestions: false
            }
        };
        this.fetchData = this.fetchData.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.timeFilter = this.timeFilter.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        const url = settings.api_server + "/watch/fetch";
        fetch (url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function(response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function(data) {
                this.setState({...this.state, loading: false, tasks: data});
            }.bind(this));
    }

    updateFilters(filters) {
        this.setState({...this.state, "filters": filters});
    }

    // Accepts all tasks, returns filtered array based on new data
    dataFilters(tasks) {
        return tasks
            // filter new images
            .filter(t => !this.state.filters.newImages || (
                t.image_request != null && t.image_request.completed)
            )
            // filter new translations
            .filter(t => !this.state.filters.newTranslations || (
                t.translation_info != null &&
                    t.translation_info.translation_completed)
            )
            // filter new questions
            .filter(t => !this.state.filters.newQuestions || (
                t.question != null && t.question.replied && !t.question.read)
            );
    }

    // Filter callback to use for time (accepts 1 task, returns true/false)
    timeFilter(task) {
        const auction_time = task["watch_info"]["auction_time"];

        // check for filters to today/tomorrow
        if (this.state.filters.time == "Today") {
            return auction_time != null && isTodayTZ(auction_time);
        }
        if (this.state.filters.time == "Tomorrow") {
            return auction_time != null && isTomorrowTZ(auction_time);
        }
        // otherwise, only valid if no time filter
        return this.state.filters.time == null;
    }

    render() {
        let tableBody;
        // Loading message
        if (this.state.loading) {
            tableBody = <tbody><tr><td colSpan={NUM_TABLE_COLS}>
                Loading... <Spinner animation="border" role="status" variant="primary"/>
            </td></tr></tbody>;
        }
        // Message for no tasks
        else if (this.state.tasks.length <= 0) {
            tableBody = <tbody><tr><td colSpan={NUM_TABLE_COLS}>
                No tasks to display
            </td></tr></tbody>;
        }
        else {
            const filteredTasks = this.dataFilters(this.state.tasks)
                .filter(this.timeFilter);
            // User has tasks, but filtered all of them out
            if (filteredTasks.length <= 0) {
                tableBody = <tbody><tr><td colSpan={NUM_TABLE_COLS}>
                    <b>No tasks match the current filters</b>
                </td></tr></tbody>;
            }
            // Tasks can be displayed
            else {
                tableBody = <tbody>{filteredTasks.map(t =>
                    <TaskRow key={t["watch_info"]["watch_id"]} watch={t}
                        forceUpdate={this.fetchData}/>)}
                </tbody>;
            }
        }

        return (
            <div>
                <Container className="wideContainer">
                    <PCANav isAdmin={this.props.isAdmin} />
                    <DashNav which={"#/taskManager"}/>
                    <Row>
                        <Col className="whiteTitle">Task Manager</Col>
                        <Col className="align-bottom text-right">
                            <TaskPanel setFilters={this.updateFilters}/>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="whiteBackground dropShadow wideContainer">
                    <Table striped bordered size="sm" hover>
                        <thead>
                            <tr>
                                {TABLE_COLS.map((label, i) => <th key={i}>
                                    {label}
                                </th>)}
                            </tr>
                        </thead>
                        {tableBody}
                    </Table>
                </Container>
            </div>
        );
    }
}

export default TaskManager;
