import React from "react";
import {
    FormControl,
    InputGroup,
} from "react-bootstrap";

import commaNumbers from "./commaNumbers";
import { stripCommas } from "../tools/numbers";

/**
 * A controlled component which displays its numeric value with commas inserted,
 * and calls onChange with the commas removed. Clearing the input calls onChange
 * with a value of 0.
 * @param {Object} props
 * @param {string} props.controlId the value to pass to FormGroup.controlId
 * @param {number} props.value the value to display, as a number
 * @param {(value: number) => void} props.onChange called when the input is
 * changed, but only if the value is a valid number (otherwise the change is
 * ignored).
 * @param {React.ReactNode} [props.symbol] currency symbol, yen sign by default.
 * @param {boolean} [props.thousands] if true, adds a ",000" to the end of the
 * input. This is just a visual change: the value prop is not divided by 1000
 * to be displayed, and the value passed to onChange is not multiplied by 1000.
 * @param {boolean} [props.disabled] if true, disable the text input.
 * @param {string} [props.placeholder] placeholder value passed to FormControl.
 * @param {boolean} [props.rightAlign] if true, the contents of the text input
 * are right-aligned. True by default if `thousands` is true, false by default
 * if `thousands` is false.
 */
export default function MoneyInput({
    value,
    onChange,
    symbol = <>&yen;</>,
    thousands = false,
    disabled = false,
    placeholder,
    rightAlign = thousands,
})
{
    // We want to allow certain values like "" or "-" to exist in the input,
    // even if they aren't valid numbers (otherwise clearing the input or
    // entering negative values would be very counterintuitive). If the input is
    // "" or "-" we'll call onChange with a value of zero, but display the
    // original "" or "-" so the user can proceed to enter a valid number.
    const [internalValue, setInternalValue] = React.useState(commaNumbers(value));
    const [useInternalValue, setUseInternalValue] = React.useState(false);

    const onInputChanged = React.useCallback((e) => {
        const val = e.target.value;
        let num;
        // we want to treat "" or "-" as a numeric value of 0 (so clearing the
        // text input behaves predictably), but also not replace them with "0"
        // (so the user can enter negative values easily)
        if (val === "" || val === "-")
        {
            setUseInternalValue(true);
            setInternalValue(val);
            num = 0;
        }
        else
        {
            setUseInternalValue(false);
            num = stripCommas(val);
        }
        // ignore the change if it makes the value invalid
        if (!isNaN(num))
        {
            onChange(num);
        }
    }, [onChange]);

    const displayedValue = useInternalValue ?
        internalValue
        :
        commaNumbers(value);

    return <InputGroup size="sm">
        <InputGroup.Prepend>
            <InputGroup.Text>{symbol}</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
            value={displayedValue}
            onChange={onInputChanged}
            placeholder={placeholder}
            disabled={disabled}
            style={{textAlign: rightAlign ? "right" : "initial"}}
        />
        {thousands && <InputGroup.Append>
            <InputGroup.Text>,000</InputGroup.Text>
        </InputGroup.Append>}
    </InputGroup>;
}
