import React, { Component } from "react";
import { Link } from "react-router-dom";

import "../App.css";
import { connect } from "react-redux";

import * as Datetime from "react-datetime";
import "../../node_modules/react-datetime/css/react-datetime.css";

import {
    ListGroup,
    Card,
    FormControl,
    Button,
    Row, Col,
    Form, Table
} from "react-bootstrap";

import commaNumber from "../widgets/commaNumbers";
import { stripCommas as getNumber } from "../tools/numbers";


import { settings } from "../settings";

class PaymentTool extends Component {
    constructor () {
        super();
        this.defaultState = {
            loading: false,
            message: null,
            queryString: "",
            userList: [],
            typeOptions: [],
            submission: {
                user: null,
                date: new Date(),
                payType: null,
                amount: "",
                invoiceList: [],
                note: "",
                topUpRequests: true
            }
        };
        this.state = {
            ...this.defaultState
        };
        this.customerField = React.createRef();

        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleSubmissionChange = this.handleSubmissionChange.bind(this);
        this.handleSubmissionNumberChange = this.handleSubmissionNumberChange.bind(this);
        this.handleInvoiceChange = this.handleInvoiceChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.handlePayment = this.handlePayment.bind(this);
        this.userSearch = this.userSearch.bind(this);
        this.setUser = this.setUser.bind(this);
        this.getPayTypes = this.getPayTypes.bind(this);
        this.sufficientData = this.sufficientData.bind(this);
        this.handleTopUp = this.handleTopUp.bind(this);
    }

    componentDidMount () {
        this.getPayTypes();
    }

    getPayTypes () {
        const url = settings.api_server + "/config_items/payment_types";

        fetch(url, {
            credentials: "include",
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    console.log(response);
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                const options = data.config_value.split(",");
                this.defaultState = ({ ...this.defaultState, typeOptions: options });
                this.setState({ ...this.state, typeOptions: options });
            }.bind(this));
    }

    handleUserChange (e) {
    // Could be debounced, but is not frequently used.
        if (e.target.value.length > 0) {
            this.setState({ ...this.state, queryString: e.target.value });
            this.userSearch(e.target.value);
        } else {
            // clear the box if there's no data
            this.setState({ ...this.state, userList: [], queryString: e.target.value });
        }
    }

    handleSubmissionChange (e) {
        const name = e.target.name;
        const val = e.target.value;
        this.setState({ ...this.state, submission: { ...this.state.submission, [name]: val } });
    }

    handleSubmissionNumberChange (e) {
        /*
        We want to store the 'pure' number that is
        in the field
        */
        const name = e.target.name;
        const val = getNumber(e.target.value);
        this.setState({ ...this.state, submission: { ...this.state.submission, [name]: val } });
    }

    handleRadioChange (e) {
        const name = e.target.name;
        const val = e.target.id;
        this.setState({ ...this.state, submission: { ...this.state.submission, [name]: val } });
    }

    handleInvoiceChange (e) {
        const name = e.target.name;
        const val = e.target.value;
        const newState = this.state;
        const newList = newState.submission.invoiceList;
        for (let i = 0; i < newList.length; i++) {
            if (newList[i].invoice_id === name) {
                newList[i].amount = val;
            }
        }
        newState.invoiceList = newList;
        this.setState(newState);
    }

    handleTopUp () {
        this.setState({ ...this.state, submission: { ...this.state.submission, topUpRequests: !this.state.submission.topUpRequests } });
    }

    handlePayment () {
        if (this.sufficientData()) {
            this.setState({ ...this.state, loading: true });
            const postData = this.state.submission;
            const url = settings.api_server + "/invoice/admin/payment";

            fetch(url, {
                credentials: "include",
                body: JSON.stringify(postData),
                method: "POST",
                headers: {
                    "content-type": "application/json"
                }
            })
                .then(function (response) {
                    if (response.status >= 400) {
                        console.log(response);
                        this.setState({ ...this.state, message: response });
                        throw new Error("Bad response from server");
                    }
                    return response.json();
                })
                .then(function (data) {
                    console.log(data);
                    this.setState({ ...this.defaultState });
                    this.customerField.current.focus();
                }.bind(this));
        }
    }

    userSearch (query) {
        const url = settings.api_server + "/user/getUsers/" + query;

        fetch(url, {
            credentials: "include",
            method: "POST",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                this.setState({ ...this.state, userList: data });
            }.bind(this));
    }

    sufficientData () {
        let result = true;
        const submission = this.state.submission;
        let message = "";
        let totalPaid = 0;
        if (submission.user === null) {
            result = false;
            message += "User Cannot be Null.\n";
        }
        if (submission.payType === null) {
            result = false;
            message += "Payment Type Cannot be Null.\n";
        }
        if (submission.amount < 0) {
            result = false;
            message += "Amount paid must be greater than zero.\n";
        }

        submission.invoiceList.forEach(invoice => {
            totalPaid += invoice.amount;
            if (invoice.amount > invoice.owing) {
                result = false;
                message += "Amount paid on invoice " + invoice.invoice_id + " must not be more than what is owing.\n";
            }
            if (invoice.amount < 0) {
                result = false;
                message += "Amount paid on invoice " + invoice.invoice_id + " must be greater than zero.\n";
            }
        }
        );
        if (totalPaid > submission.amount) {
            message += "Invoice payments sum exceeds amount recieved.\n";
        }
        if (!result) {
            this.setState({ ...this.state.loading, message });
        }
        return result;
    }

    setUser (user) {
        this.setState({ ...this.state, queryString: "", userList: [], submission: { ...this.state.submission, user: { user_id: user.user_id, username: user.username, userString: user.firstname + " " + user.lastname } } });

        const url = settings.api_server + "/invoice/admin/single/" + user.user_id;

        fetch(url, {
            credentials: "include",
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                const newState = this.state;
                for (let i = 0; i < data.length; i++) {
                    data[i].amount = 0;
                }
                newState.submission.invoiceList = data;
                this.setState(newState);
            }.bind(this));
    }

    render () {
        const userData = this.state.userList.map(x => <ListGroup.Item onClick={() => this.setUser(x)} action id={x.user_id} key={x.user_id}><Row><Col md={6}>{x.username} </Col><Col md={6}>{x.firstname} {x.lastname}</Col></Row></ListGroup.Item>);

        // list the payment types
        // state value this.state.submission.payType is set
        // check off values appropriately
        const paymentType = this.state.typeOptions.map(x => <Form.Check key={x} onChange={this.handleRadioChange} checked={x === this.state.submission.payType} type='radio' label={x} name='payType' id={x} />);

        let depositTotal = this.state.submission.amount;

        for (let i = 0; i < this.state.submission.invoiceList.length; i++) {
            depositTotal -= this.state.submission.invoiceList[i].amount;
        }

        const invoices = this.state.submission.invoiceList.map(x =>
            <tr key={x.invoice_id}>
                <td><Link to={"/purchase_detail/" + x.purchase_id} target="_blank" rel="noopener noreferrer">{x.invoice_id}</Link></td>
                <td>¥{x.owing}</td>
                <td><Form.Control as="textarea" name={x.invoice_id} value={x.amount === 0 ? "" : x.amount} onChange={this.handleInvoiceChange} rows={1} /></td>
            </tr>
        );

        return (<Card id="collapsible-Card-example-2" >
            <Card.Header>
                <Card.Title>
                    Speed Payment Tool
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Col md={12}>
                    <Row>
                        <Col md={5}>
                            Date:
                            {<Datetime closeOnSelect value={this.state.submission.date} onChange={(e) => this.setState({ ...this.state, submission: { ...this.state.submission, date: e._d } })} dateFormat="YYYY-MM-DD" timeFormat={false} />}
                            Customer:
                            {this.state.submission.user != null
                                ? <div>{" " + this.state.submission.user.userString + " - " + this.state.submission.user.username}</div>
                                : <div>
                                    <FormControl autoComplete="off" ref={this.customerField} value={this.state.queryString} onChange={this.handleUserChange} />
                                    {userData}
                                </div>
                            }
                            Amount:
                            <Form.Control type="text" name='amount' value={commaNumber(this.state.submission.amount)} onChange={this.handleSubmissionNumberChange} />
                        </Col>
                        <Col>
                            Payment Type:
                            {paymentType}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            Invoices:
                            <Table>
                                <tbody>
                                    <tr>
                                        <td>Deposit</td>
                                        <td></td>
                                        <td>{commaNumber(depositTotal)}</td>
                                    </tr>
                                    <tr>
                                        <th>Invoice Id</th>
                                        <th>Amount Owing</th>
                                        <th>Amount to be paid</th>
                                    </tr>
                                    {invoices}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            Note:
                            <Form.Control as="textarea" name='note' value={this.state.submission.note} onChange={this.handleSubmissionChange} rows={2} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Check label="Top up requests" checked={this.state.submission.topUpRequests} onChange={this.handleTopUp} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                            {this.state.message !== null && this.state.message.split("\n").map((item, key) => {
                                return (<span key={key}>{item}<br /></span>);
                            })
                            }

                            <Button disabled={this.state.loading} onClick={this.handlePayment} > Save Payment</Button>
                            &nbsp;
                            <Button onClick={() => this.setState({ ...this.defaultState })} variant="outline-danger">Reset</Button>
                        </Col>
                    </Row>
                </Col>
            </Card.Body>
        </Card>);
    }
}

const mapStateToProps = state => {
    state;
    return {
    };
};


export default connect(mapStateToProps)(PaymentTool);
