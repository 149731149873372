
import React, { Component } from "react";
import "./App.css";

import PCANav from "./dashboard-components/navbar";

import { connect } from "react-redux";

import { settings } from "./settings";

import {
    setRequestList,
    clearRequestList,
    showRequestModal
} from "./actions/image_request_list_actions";

import RequestedImageModal from "./admin_views/requested_image_modal";
import LastRefresh from "./widgets/LastRefresh";

import {
    Container,
    Table,
    Button
} from "react-bootstrap";

import { prettyDateTime } from "./functions";

class ImageRequestItemComponent extends Component {
    constructor () {
        super();
        this.show = this.show.bind(this);
    }

    show () {
        this.props.showRequestModal(this.props.data.image_request_id);
    }

    render () {
        const x = this.props.data;
        let lock_who = null;
        const style = { cursor: "pointer" };
        if (x.lock.locked_by !== null) {
            lock_who = x.lock.locked_by;
            if (!x.lock.my_lock) {
                style.backgroundColor = "#ff8080";
            }
        }
        // {x.user.firstname} {x.user.lastname}
        const who = this.props.data.requesters.map(user => user.firstname + " " + user.lastname).join(", ");
        return <tr id={x.image_request_id} onClick={this.show} key={x.image_request_id} style={style}>
            <td>{x.car_data}</td>
            <td>{prettyDateTime(x.requested_at, false, false)}</td>
            <td>{who}</td>
            <td>{x.completed ? "Completed" : "Not Completed"}</td>
            <td>{lock_who}</td>
        </tr>;
    }
}
const mapStateToPropsBid = state => {
    state;
    return {
    };
};

const mapDispatchToPropsBid = dispatch => ({
    showRequestModal: (which) => dispatch(showRequestModal(which))
});
const ImageRequestItem = connect(mapStateToPropsBid, mapDispatchToPropsBid)(ImageRequestItemComponent);

class AllImageRequests extends Component {
    constructor () {
        super();
        this.fetchData = this.fetchData.bind(this);
        this.clickHandler = this.clickHandler.bind(this);

        this.state = { refreshing: false, lastUpdate: null };
        this.fetchInterval = setInterval(this.fetchData, 2 * 60 * 1000); // in milliseconds
    }

    componentDidMount () {
    // load all the question threads
        this.fetchData();
    }

    componentWillUnmount () {
        this.props.clearRequestList();
        // clear the interval
        clearInterval(this.fetchInterval);
    }

    fetchData () {
    /*
        -1 gets current user.
        number gets specific user (admin-only)
        */
        const url = settings.api_server + "/image_requests/all";

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a list of bids (even if it's empty)
                this.props.setRequestList(data);
                this.setState({ ...this.state, lastUpdate: new Date() });
            }.bind(this));
    }

    clickHandler (e) {
    // show this question
        this.props.setShowThreadId(parseInt(e.target.value, 10));
    }

    render () {
        if (!this.props.request_list_loaded) { return <p>Loading...</p>; }

        let requests = this.props.request_list.map(x => <ImageRequestItem key={x.image_request_id} data={x} />);
        if (requests.length === 0) { requests = <tr><td colSpan="5">None</td></tr>; }

        return (
            <div>
                <PCANav isAdmin={this.props.isAdmin} />
                <RequestedImageModal update={this.fetchData} />
                <Container>
                    <h1 className="whiteTitle">All Image Requests: <Button disabled={this.state.refreshing} onClick={this.fetchData}>Refresh</Button></h1>
                    <p>Last update: <LastRefresh epoch={this.state.lastUpdate}></LastRefresh></p>
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>Car info</th>
                                <th>Requested at</th>
                                <th>By</th>
                                <th>Is completed</th>
                                <th>Lock info</th>
                            </tr>
                        </thead>
                        <tbody>
                            {requests}
                        </tbody>
                    </Table>
                </Container>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        isAdmin: state.profile.is_admin,
        request_list: state.image_request_list.request_list,
        request_list_loaded: state.image_request_list.request_list_loaded
    };
};

const mapDispatchToProps = dispatch => ({
    setRequestList: (list) => dispatch(setRequestList(list)),
    showRequestModal: (which) => dispatch(showRequestModal(which)),
    clearRequestList: () => dispatch(clearRequestList())
});

export default connect(mapStateToProps, mapDispatchToProps)(AllImageRequests);
